import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  ComposedModal,
  ListItem,
  ModalBody,
  ModalHeader,
  OrderedList,
} from "@carbon/react";
import { Document } from "@carbon/icons-react";

function PageHeader({ headerTitle, isProductsPage, isProductDescPage }) {
  const [showModal, setShowModal] = useState(false);

  const hideModal = () => {
    setShowModal(false);
  };

  return (
    <div className="page-header">
      <div className="page-header-main">
        <Breadcrumb>
          <BreadcrumbItem>
            <a href="/#">HomePage</a>
          </BreadcrumbItem>
          {isProductsPage ? (
            <BreadcrumbItem href="/products">Products</BreadcrumbItem>
          ) : (
            <></>
          )}
          {isProductDescPage ? (
            <BreadcrumbItem href="/product/:id">
              Product Description
            </BreadcrumbItem>
          ) : (
            <></>
          )}
        </Breadcrumb>
        <h2>{headerTitle}</h2>
      </div>
      <div className="guide-me">
        <Button
          renderIcon={Document}
          iconDescription="Add"
          size="md"
          onClick={() => {
            setShowModal(true);
          }}
        >
          Guide
        </Button>
      </div>
      {showModal && (
        <div className="guide-modal-div">
          <ComposedModal open={showModal} onClose={hideModal}>
            <ModalHeader title={"Retail Demo Guide"} />
            <ModalBody>
              {
                "Welcome! To make the most of your experience, please follow these steps for either Flow 1 or Flow 2 sequentially to navigate through the retail demo:"
              }
              <br /> <br />
              <h5>Flow 1:</h5>
              <OrderedList className="instructions">
                <ListItem>
                  <strong>Start the Conversation:</strong>
                  <OrderedList nested>
                    <ListItem>Access the chat widget interface.</ListItem>
                    <ListItem>
                      Enter the text:{" "}
                      <strong>"I'm looking for a washing machine"</strong>.
                    </ListItem>
                  </OrderedList>
                </ListItem>
                <ListItem>
                  <strong>Filter Items:</strong>
                  <OrderedList nested>
                    <ListItem>
                      Click on either of the filter options visible on the chat
                      widget, for ex. <strong>"Front Load"</strong>
                    </ListItem>
                    <ListItem>
                      Notice the product list is updated and shows the "front
                      load washing machines" as the priority.
                    </ListItem>
                  </OrderedList>
                </ListItem>
                <ListItem>
                  <strong>Selecting Options:</strong>
                  <OrderedList nested>
                    <ListItem>
                      To select a product, enter <strong>"1st option"</strong>{" "}
                      in the chat widget.
                    </ListItem>
                  </OrderedList>
                </ListItem>
                <ListItem>
                  <strong>Ask Queries (if any):</strong>
                  <OrderedList nested>
                    <ListItem>
                      After selecting the first option, inquire about specific
                      details by typing these questions individually:
                    </ListItem>
                    <OrderedList nested>
                      <ListItem>Do other users see any issues?</ListItem>
                      <ListItem>
                        Can you highlight some features of this washing machine?
                      </ListItem>
                      <ListItem>
                        Are people satisfied with the product quality?
                      </ListItem>
                    </OrderedList>
                  </OrderedList>
                </ListItem>
              </OrderedList>
              <h5>Flow 2:</h5>
              <OrderedList className="instructions">
                <ListItem>
                  <strong>Start the Conversation:</strong>
                  <OrderedList nested>
                    <ListItem>Access the chat widget interface.</ListItem>
                    <ListItem>
                      Enter the text: <strong>"I need a mini fridge"</strong>.
                    </ListItem>
                  </OrderedList>
                </ListItem>
                <ListItem>
                  <strong>Selecting Options:</strong>
                  <OrderedList nested>
                    <ListItem>
                      To select a product, enter <strong>"1st option"</strong>{" "}
                      in the chat widget.
                    </ListItem>
                  </OrderedList>
                </ListItem>
                <ListItem>
                  <strong>Ask Queries (if any):</strong>
                  <OrderedList nested>
                    <ListItem>
                      After selecting the first option, inquire about specific
                      details by typing these questions individually:
                    </ListItem>
                    <OrderedList nested>
                      <ListItem>What do people say about its freezer?</ListItem>
                      <ListItem>
                        What are the sentiments about the capacity of this
                        fridge?
                      </ListItem>
                    </OrderedList>
                  </OrderedList>
                </ListItem>
                <ListItem>
                  <strong>Accessing PDF Manual Information:</strong>
                  <OrderedList nested>
                    <ListItem>
                      To gather detailed product information, ask the following
                      questions. The responses should be sourced from the PDF
                      manual of the product:
                    </ListItem>
                    <OrderedList nested>
                      <ListItem>
                        How much is the warranty period for the compressor?
                      </ListItem>
                      <ListItem>Is the warranty transferable?</ListItem>
                      <ListItem>
                        What should I do when I go for a long vacation?
                      </ListItem>
                      <ListItem>How do I clean the fridge?</ListItem>
                      <ListItem>Please share some energy-saving tips.</ListItem>
                    </OrderedList>
                  </OrderedList>
                </ListItem>
              </OrderedList>
            </ModalBody>
          </ComposedModal>
        </div>
      )}
    </div>
  );
}

export default PageHeader;
